:root{
  --esri-radius: 4px;
}
.esri-ui-corner .esri-component{
  border-radius: var(--esri-radius);
}
.esri-zoom .esri-widget--button:first-child{
  margin-bottom: 1px;
  border-radius: var(--esri-radius) var(--esri-radius) 0 0;
}
.esri-zoom .esri-widget--button:last-child{
  margin-top: 1px;
  border-radius: 0 0 var(--esri-radius) var(--esri-radius);
}

.esri-popup__main-container{
  border-radius: var(--esri-radius);
}