/*--dx-texteditor--*/
.dx-texteditor.dx-editor-filled{
  background-color: var(--clr-background-secondary);
}
.dx-texteditor.dx-editor-filled.dx-state-focused,
.dx-texteditor.dx-editor-filled.dx-state-hover{
  background-color: var(--clr-background-hover);
}
.dx-texteditor-label{
  color: var(--clr-text-muted);
}
.dx-texteditor.dx-editor-filled.dx-state-hover .dx-texteditor-label{
  color: var(--clr-text);
}
.dx-texteditor.dx-editor-filled.dx-state-focused .dx-texteditor-label{
  color: var(--clr-primary);
}
.dx-texteditor.dx-editor-filled.dx-invalid .dx-texteditor-label{
  color: var(--clr-warn);
}

/*--checkbox--*/
.dx-checkbox{
  margin: 0 0.375rem;;
}
.dx-checkbox-text{
  padding-left: 1.25rem;
}

/*---popup---*/
.dx-popup-bottom.dx-toolbar{
  /* border-top: 1px solid var(--clr-border); */
  padding: 1rem;
 }

/*---tabs---*/
.dx-tabs{
  border-bottom: 1px solid var(--clr-border);
}

/*---text-box---*/
.text-box-primary.dx-texteditor.dx-editor-filled{
  background: white;
}
.text-box-search.dx-texteditor.dx-editor-filled .dx-texteditor-input{
  padding-right: 3rem;
  padding-left: 3rem;
}
.text-box-search.dx-texteditor.dx-editor-filled .dx-placeholder::before{
  padding-left:3rem;
}

/*---switch---*/
.first-switch{
  padding-left: 0.375rem;
}
.switch-label{
  width: 100%;
  color: var(--clr-text-secondary);
  white-space: normal;
}
.dx-switch-on-value ~ .switch-label{
  color: var(--clr-text);
}
.switch-label-off{
  order: -1;
  color: var(--clr-text);
}
.dx-switch-on-value ~ .switch-label-off{
  color: var(--clr-text-secondary);
}

/*---list---*/
.dx-list-item .dx-icon{
  color: var(--clr-text);
}
.list-compact .dx-list-item-content{
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 2.5rem;
}

/*---filter---*/
.dx-filterbuilder{
  background-color: transparent;
}
.dx-filterbuilder-item-value-text{
  display: flex;
  flex-wrap: wrap;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.dx-filterbuilder .dx-selectbox-container{
  min-width: 240px;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text:last-child{
  flex:1;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text{
  white-space: break-spaces;
}

/*---tag-box---*/
.dx-tag-content{
  white-space: break-spaces;
  text-align: left;
}
.dx-tag-remove-button{
  text-align: center;
}

/*---btn---*/
.dx-button-has-icon .dx-icon.lg-icon{
  font-size: 1.5rem;
}
.text-stroke::before{
  -webkit-text-stroke: 0.2px white;
}

/*---treeview---*/
.dx-treeview .dx-checkbox-checked .dx-checkbox-icon{
  background-color:var(--clr-success);
}

/*---dx-empty-message---*/
.dx-list .dx-empty-message,
.dx-empty-message {
  font-size: 0.875rem;
  color: var(--clr-text-secondary);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}