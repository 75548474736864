
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.text-primary {
  color: #5c6a86;
}

.text-secondary {
  color: #8d98ad;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ava {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  text-align: center;
  margin: 0.375rem;
  display: inline-block;
  background: #e0e0e0;
  border-radius: 50%;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.row {
  margin: 0 -1rem;
  min-height: 3rem;
}

.col-1, .col-ex-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1,
.col-2, .col-ex-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2,
.col-2_4, .col-ex-2_4, .col-sm-2_4, .col-md-2_4, .col-lg-2_4, .col-xl-2_4,
.col-3, .col-ex-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3,
.col-3_5, .col-ex-3_5, .col-sm-3_5, .col-md-3_5, .col-lg-3_5, .col-xl-3_5,
.col-4, .col-ex-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4,
.col-4_8, .col-ex-4_8, .col-sm-4_8, .col-md-4_8, .col-lg-4_8, .col-xl-4_8,
.col-5, .col-ex-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5,
.col-6, .col-ex-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6,
.col-7, .col-ex-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7,
.col-8, .col-ex-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8,
.col-9, .col-ex-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9,
.col-9_6, .col-ex-9_6, .col-sm-9_6, .col-md-9_6, .col-lg-9_6, .col-xl-9_6,
.col-10, .col-ex-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10,
.col-11, .col-ex-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11,
.col-12, .col-ex-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12 {
  position: relative;
  min-height: 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.col-12,
.col-ex-12 {
  width: 100%;
}

.col-11,
.col-ex-11 {
  width: 91.7%;
}

.col-10,
.col-ex-10 {
  width: 83.3%;
}

.col-9_6,
.col-ex-9_6 {
  width: 80%;
}

.col-9,
.col-ex-9 {
  width: 75%;
}

.col-8,
.col-ex-8 {
  width: 66.666%;
}

.col-7,
.col-ex-7 {
  width: 58.3%;
}

.col-6,
.col-ex-6 {
  width: 50%;
}

.col-5,
.col-ex-5 {
  width: 41.666%;
}

.col-4_8,
.col-ex-4_8 {
  width: 40%;
}

.col-4,
.col-ex-4 {
  width: 33.3%;
}

.col-3_5,
.col-ex-3_5 {
  width: 29.166%;
}

.col-3,
.col-ex-3 {
  width: 25%;
}

.col-2_4,
.col-ex-2_4 {
  width: 20%;
}

.col-2,
.col-ex-2 {
  width: 16.666%;
}

.col-1,
.col-ex-1 {
  width: 8.333%;
}

@media (min-width: 48rem) {
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.7%;
  }
  .col-sm-10 {
    width: 83.3%;
  }
  .col-sm-9_6 {
    width: 80%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.666%;
  }
  .col-sm-7 {
    width: 58.3%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.666%;
  }
  .col-sm-4_8 {
    width: 40%;
  }
  .col-sm-4 {
    width: 33.3%;
  }
  .col-sm-3_5 {
    width: 29.166%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2_4 {
    width: 20%;
  }
  .col-sm-2 {
    width: 16.666%;
  }
  .col-sm-1 {
    width: 8.333%;
  }
}
@media (min-width: 50rem) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.7%;
  }
  .col-md-10 {
    width: 83.3%;
  }
  .col-md-9_6 {
    width: 80%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.666%;
  }
  .col-md-7 {
    width: 58.3%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.666%;
  }
  .col-md-4_8 {
    width: 40%;
  }
  .col-md-4 {
    width: 33.3%;
  }
  .col-md-3_5 {
    width: 29.166%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2_4 {
    width: 20%;
  }
  .col-md-2 {
    width: 16.666%;
  }
  .col-md-1 {
    width: 8.333%;
  }
}
@media (min-width: 75rem) {
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.7%;
  }
  .col-lg-10 {
    width: 83.3%;
  }
  .col-lg-9_6 {
    width: 80%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.666%;
  }
  .col-lg-7 {
    width: 58.3%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.666%;
  }
  .col-lg-4_8 {
    width: 40%;
  }
  .col-lg-4 {
    width: 33.3%;
  }
  .col-lg-3_5 {
    width: 29.166%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2_4 {
    width: 20%;
  }
  .col-lg-2 {
    width: 16.666%;
  }
  .col-lg-1 {
    width: 8.333%;
  }
}
@media (min-width: 86rem) {
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.7%;
  }
  .col-xl-10 {
    width: 83.3%;
  }
  .col-xl-9_6 {
    width: 80%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.666%;
  }
  .col-xl-7 {
    width: 58.3%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.666%;
  }
  .col-xl-4_8 {
    width: 40%;
  }
  .col-xl-4 {
    width: 33.3%;
  }
  .col-xl-3_5 {
    width: 29.166%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2_4 {
    width: 20%;
  }
  .col-xl-2 {
    width: 16.666%;
  }
  .col-xl-1 {
    width: 8.333%;
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.w-view {
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  border: 0.0625rem solid #d3d7e1;
  border-radius: 0.125rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.legend-icon {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legend-icon--point {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.375rem;
  border-radius: 50%;
}
.legend-icon--polyline {
  height: 0.125rem;
  margin: 0.6875rem 0.125rem;
}
.legend-icon--polygon {
  margin-top: -0.125rem;
  -webkit-transform: perspective(1rem) rotateX(30deg);
  -moz-transform: perspective(1rem) rotateX(30deg);
  transform: perspective(1rem) rotateX(30deg);
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.26);
}
.legend-icon ~ span {
  width: 100%;
  min-height: 2.25rem;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.625rem 0 0.625rem 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.legend-icon ~ span:hover > span {
  border-bottom: 0.0625rem dashed #5c6a86;
}

.panel-header {
  width: 100%;
  height: 3rem;
  padding: 0.375rem;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  border-top: 0.0625rem solid #d3d7e1;
  border-bottom: 0.0625rem solid #d3d7e1;
}
.panel-header > span {
  width: 100%;
  padding: 0.375rem;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.panel-header > .btn-icon {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
}
.panel-header > .btn-icon ~ span {
  padding-right: 3rem;
}
.panel-header > .btn-icon ~ .btn-icon {
  right: 3.375rem;
}
.panel-header > .btn-icon ~ .btn-icon ~ span {
  padding-right: 6rem;
}
.panel-header ~ .legend-list {
  height: calc(100% - 6rem);
}

.legend-list {
  position: relative;
  height: 100%;
  display: block;
}
.legend-list ul {
  height: 100%;
  line-height: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.legend-list ul > li {
  padding: 0.375rem 0.75rem;
}
.legend-list dx-list {
  height: 100%;
  line-height: 1.5rem;
}
.legend-list dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  color: #5c6a86;
}
.legend-list dx-list .dx-list-item {
  cursor: default;
  border-bottom: 0.0625rem solid #e8ecf7;
}
.legend-list dx-list .dx-list-item .legend-item .icon {
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;
  color: #5c6a86;
}
.legend-list dx-list .dx-list-item .legend-item .icon ~ .icon {
  left: 2.25rem;
}
.legend-list dx-list .dx-list-item .legend-item .icon input {
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer !important;
  overflow: hidden;
  z-index: 8;
  padding-left: 2.25rem;
}
.legend-list dx-list .dx-list-item .legend-item .icon ~ .icon--count {
  left: auto;
  right: 2.25rem;
  overflow: hidden;
  text-align: center;
}
.legend-list dx-list .dx-list-item .legend-item .icon ~ .icon--count .count {
  min-width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  padding: 0 0.25rem;
  margin: 0.375rem 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
  box-sizing: border-box;
  border: 0.0625rem solid #d3d7e1;
  border-color: #5c6a86;
  border-radius: 0.125rem;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.legend-list dx-list .dx-list-item .legend-item .icon ~ .icon--count .count.esri-icon-layer-list {
  font-size: 1rem;
}
.legend-list dx-list .dx-list-item .legend-item .icon ~ .icon--count:hover .count {
  border-color: #1492dc;
}
.legend-list dx-list .dx-list-item .legend-item .icon:before {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
}
.legend-list dx-list .dx-list-item .legend-item .icon.filled, .legend-list dx-list .dx-list-item .legend-item .icon:hover {
  color: #1492dc;
}
.legend-list dx-list .dx-list-item .legend-item .icon.icon--traffic-light.filled {
  color: #ed1941;
}
.legend-list dx-list .dx-list-item .legend-item .icon--btn {
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;
  color: #5c6a86;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legend-list dx-list .dx-list-item .legend-item .icon--btn:before {
  top: 0.375rem !important;
  left: 0.375rem !important;
}
.legend-list dx-list .dx-list-item .legend-item .icon--btn ~ .legend-item-content {
  padding-right: 4.5rem;
}
.legend-list dx-list .dx-list-item .legend-item .legend-btn {
  width: 4.5rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  left: 0;
}
.legend-list dx-list .dx-list-item .legend-item .legend-btn ~ dx-slider {
  padding-left: 4.5rem;
}
.legend-list dx-list .dx-list-item .legend-item .legend-item-content-bottom {
  width: 100%;
  position: relative;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.legend-list dx-list .dx-list-item .legend-item .legend-item-content-bottom--public {
  width: 100%;
}
.legend-list dx-list .dx-list-item .legend-item--active .legend-icon ~ span {
  white-space: normal;
}
.legend-list dx-list .dx-list-item .legend-item--active .legend-item-content-bottom {
  opacity: 1;
  height: 2.25rem;
  overflow: visible;
}
.legend-list dx-list .dx-list-item.dx-state-active, .legend-list dx-list .dx-list-item.dx-state-focused {
  color: #5c6a86;
  background: #fff !important;
}
.legend-list dx-list .dx-list-item.dx-state-active.dx-list-item-selected, .legend-list dx-list .dx-list-item.dx-state-focused.dx-list-item-selected {
  background: #e8ecf7 !important;
}
.legend-list dx-list .dx-list-item.dx-list-item-ghost-reordering {
  -webkit-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
}
.legend-list dx-list .dx-list-item.dx-list-item-ghost-reordering .dx-list-reorder-handle-container .dx-list-reorder-handle:before {
  content: "\e949";
  color: #1492dc;
}
.legend-list dx-list .dx-list-item:first-child {
  margin-top: 0;
}
.legend-list dx-list .dx-list-item .dx-list-item-content {
  padding: 0;
  position: relative;
  vertical-align: top;
  overflow: visible;
}
.legend-list dx-list .dx-list-item .dx-list-item-content .legend-item-content {
  cursor: pointer;
  min-height: 2.25rem;
  display: flex;
}
.legend-list dx-list .dx-list-item .dx-list-reorder-handle-container {
  width: 2.25rem;
  vertical-align: top;
}
.legend-list dx-list .dx-list-item .dx-list-reorder-handle-container .dx-list-reorder-handle {
  font-family: "icons";
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  background-position: center;
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.legend-list dx-list .dx-list-item .dx-list-reorder-handle-container .dx-list-reorder-handle:before {
  content: "\e948";
  color: #8d98ad;
}
.legend-list dx-list .dx-list-item .dx-list-reorder-handle-container .dx-list-reorder-handle:active:before {
  content: "\e949";
  color: #1492dc;
}
.legend-list dx-list .dx-list-item .dx-list-select-checkbox-container {
  width: 3rem;
}
.legend-list dx-list .dx-list-item .dx-list-select-checkbox-container .dx-checkbox {
  margin: 0 0.375rem;
}
.legend-list dx-list .dx-list-item .dx-list-select-checkbox-container .dx-checkbox .dx-checkbox-container .dx-checkbox-icon {
  border: none;
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.375rem;
  border: none;
  transition: none;
  font-family: "icons" !important;
}
.legend-list dx-list .dx-list-item .dx-list-select-checkbox-container .dx-checkbox .dx-checkbox-container .dx-checkbox-icon:before {
  content: "\e954";
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  opacity: 1;
  border: none;
  transform: none;
}
.legend-list dx-list .dx-list-item .dx-list-select-checkbox-container .dx-checkbox-checked .dx-checkbox-container .dx-checkbox-icon:before {
  content: "\e955";
}

.esri-icon-download {
  padding: 0.75rem !important;
  box-sizing: content-box;
}

@font-face {
  font-family: "icons";
  src: url("assets/fonts/icomoon.eot?o4btyx");
  src: url("assets/fonts/icomoon.eot?o4btyx#iefix") format("embedded-opentype"), url("assets/fonts/icomoon.ttf?o4btyx") format("truetype"), url("assets/fonts/icomoon.woff?o4btyx") format("woff"), url("assets/fonts/icomoon.svg?o4btyx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.5rem;
  box-sizing: content-box;
  font-family: "icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}

.icon-search:before {
  content: "\e900";
}

.icon-search.filled:before {
  content: "\e901";
}

.icon-collapse-arrow:before {
  content: "\e902";
}

.icon-collapse-arrow.filled:before {
  content: "\e903";
}

.icon-expand-arrow:before {
  content: "\e904";
}

.icon-expand-arrow.filled:before {
  content: "\e905";
}

.icon-layers:before {
  content: "\e906";
}

.icon-layers.filled:before {
  content: "\e907";
}

.icon-firter:before {
  content: "\e908";
}

.icon-firter.filled:before {
  content: "\e909";
}

.icon-tool:before {
  content: "\e90a";
}

.icon-tool.filled:before {
  content: "\e90b";
}

.icon-hunt:before {
  content: "\e90c";
}

.icon-hunt.filled:before {
  content: "\e90d";
}

.icon-home:before {
  content: "\e90e";
}

.icon-home.filled:before {
  content: "\e90f";
}

.icon-plus:before {
  content: "\e910";
}

.icon-plus.filled:before {
  content: "\e911";
}

.icon-minus:before {
  content: "\e912";
}

.icon-minus.filled:before {
  content: "\e913";
}

.icon-eye:before {
  content: "\e914";
}

.icon-eye.filled:before {
  content: "\e915";
}

.icon-help:before {
  content: "\e916";
}

.icon-help.filled:before {
  content: "\e917";
}

.icon-pin:before {
  content: "\e918";
}

.icon-pin.filled:before {
  content: "\e919";
}

.icon-close:before {
  content: "\e91a";
}

a:active.btn-icon .icon-close:before,
.icon-close.filled:before {
  content: "\e91b";
}

.icon-map:before {
  content: "\e91c";
}

.icon-map.filled:before {
  content: "\e91d";
}

.icon-marker:before {
  content: "\e91e";
}

.icon-marker.filled:before {
  content: "\e91f";
}

.icon-line:before {
  content: "\e920";
}

.icon-line.filled:before {
  content: "\e921";
}

.icon-polyline:before {
  content: "\e922";
}

.icon-polyline.filled:before {
  content: "\e923";
}

.icon-curve:before {
  content: "\e924";
}

.icon-curve.filled:before {
  content: "\e925";
}

.icon-triangle:before {
  content: "\e926";
}

.icon-triangle.filled:before {
  content: "\e927";
}

.icon-rectangle:before {
  content: "\e928";
}

.icon-rectangle.filled:before {
  content: "\e929";
}

.icon-circle:before {
  content: "\e92a";
}

.icon-circle.filled:before {
  content: "\e92b";
}

.icon-oval:before {
  content: "\e92c";
}

.icon-oval.filled:before {
  content: "\e92d";
}

.icon-polygon:before {
  content: "\e92e";
}

.icon-polygon.filled:before {
  content: "\e92f";
}

.icon-city:before {
  content: "\e930";
}

.active .icon-city:before,
.icon-city.filled:before {
  content: "\e931";
}

.icon-users:before {
  content: "\e932";
}

.active .icon-users:before,
.icon-users.filled:before {
  content: "\e933";
}

.icon-role:before {
  content: "\e934";
}

.active .icon-role:before,
.icon-role.filled:before {
  content: "\e935";
}

.icon-logout:before {
  content: "\e936";
}

.icon-logout.filled:before {
  content: "\e937";
}

.icon-edit:before {
  content: "\e938";
}

button:active .icon-edit:before,
button:active .icon-filter-add:before,
.icon-edit.filled:before {
  content: "\e939";
}

.icon-trash:before {
  content: "\e93a";
}

.icon-trash.filled:before {
  content: "\e93b";
}

.icon-arrow-top:before {
  content: "\e93c";
}

.icon-arrow-top.filled:before {
  content: "\e93d";
}

.icon-arrow-right:before {
  content: "\e93e";
}

.icon-arrow-right.filled:before {
  content: "\e93f";
}

.icon-arrow-bottom:before {
  content: "\e940";
}

.icon-arrow-bottom.filled:before {
  content: "\e941";
}

.icon-arrow-left:before {
  content: "\e942";
}

.icon-arrow-left.filled:before {
  content: "\e943";
}

.icon-save:before {
  content: "\e944";
}

.icon-save.filled:before {
  content: "\e945";
}

.icon-return:before {
  content: "\e946";
}

.icon-return.filled:before {
  content: "\e947";
}

.icon-drag-reorder:before {
  content: "\e948";
}

.icon-drag-reorder.filled:before {
  content: "\e949";
}

.icon-maximize:before {
  content: "\e94a";
}

.icon-maximize-filled:before {
  content: "\e94b";
}

.icon-minimize:before {
  content: "\e94c";
}

.icon-minimize-filled:before {
  content: "\e94d";
}

.icon-circle-plus:before {
  content: "\e94e";
}

.icon-circle-plus.filled:before {
  content: "\e94f";
}

.icon-circle-minus:before {
  content: "\e950";
}

.icon-circle-minus.filled:before {
  content: "\e951";
}

.icon-add-list:before {
  content: "\e952";
}

.icon-add-list.filled:before {
  content: "\e953";
}

.icon--traffic-light:before,
.icon-traffic-light:before {
  content: "\e954";
}

.icon--traffic-light:active:before,
.icon--traffic-light.filled:before,
.icon-traffic-light:active:before,
.icon-traffic-light.filled:before {
  content: "\e955";
}

.icon-bookmarks:before {
  content: "\e956";
}

.icon-bookmarks.filled:before {
  content: "\e957";
}

.icon-eraser:before {
  content: "\e958";
}

.icon-eraser.filled:before {
  content: "\e959";
}

.icon-distance:before {
  content: "\e95a";
}

.icon-distance.filled:before {
  content: "\e95b";
}

.icon-legend:before {
  content: "\e95c";
}

.icon-legend.filled:before {
  content: "\e95d";
}

.icon-filter-add:before {
  content: "\e95e";
}

button:active .icon-filter-add:before,
.icon-filter-add.filled:before {
  content: "\e95f";
}

.icon-filter-move:before {
  content: "\e960";
}

.btn-icon:active .icon-filter-move:before,
.icon-filter-move.filled:before {
  content: "\e961";
}

.icon-setting:before {
  content: "\e962";
}

a:active .icon-setting:before,
.icon-setting.filled:before {
  content: "\e963";
}

.icon-chart:before {
  content: "\e964";
}

.disabled .icon-chart:before,
a:active .icon-chart:before,
.icon-chart.filled:before {
  content: "\e965";
}

.icon-map-edit:before {
  content: "\e966";
}

.btn-icon:active .icon-map-edit:before,
.icon-map-edit.filled:before {
  content: "\e967";
}

.icon-download:before {
  content: "\e968";
}

.icon-download.filled:before {
  content: "\e969";
}

.icon-upload:before {
  content: "\e96a";
}

.icon-upload.filled:before {
  content: "\e96b";
}

.icon-import-file:before {
  content: "\e96c";
}

.icon-bookmark-add:before {
  content: "\e96e";
}

button:active .icon-bookmark-add:before,
.icon-bookmark-add.filled:before {
  content: "\e96f";
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
a {
  color: #1492dc;
}
a:hover {
  text-decoration: underline;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.w-btn {
  width: 100%;
  text-align: right;
  margin-top: 1rem;
}
.w-btn > dx-button {
  margin-left: 0.5rem;
}

.btn-icon {
  width: 2.25rem;
  height: 2.25rem;
  display: inline-block;
  position: relative;
  padding: 0.375rem;
  margin: 0;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  color: #5c6a86;
  background: transparent;
}
.btn-icon:hover, .btn-icon:focus {
  color: #1492dc;
}
.btn-icon:focus {
  outline: none;
}
.btn-icon.disabled {
  color: #8d98ad;
  cursor: default;
}
.btn-icon.disabled:hover, .btn-icon.disabled:focus {
  color: #8d98ad;
}

.btn-icon-client {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.375rem;
  margin: 0.375rem;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  color: #5c6a86;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn-icon-client:hover {
  color: #1492dc;
  text-decoration: none;
}

.js-btn-active:hover,
.js-btn-active {
  background: #1492dc;
  color: #fff;
}

.btn {
  width: 100%;
  height: 2.25rem;
  line-height: 1.5rem;
  padding: 0.375rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  color: #5c6a86;
  background: #fff;
  border: 0.0625rem solid #d3d7e1;
  border-radius: 0.125rem;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn__icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem;
}
.btn:hover {
  background: #fafafa;
}
.btn:disabled {
  background: #d3d7e1;
  color: #8d98ad;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text-field {
  position: relative;
  padding: 0 0 0.75rem;
}
.text-field-label {
  width: 100%;
  min-height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.text-field-input {
  width: 100%;
  height: 2.25rem;
  line-height: 1.5rem;
  box-sizing: border-box;
  border-radius: 0.125rem;
}
.text-field-input--disabled {
  background: #e8ecf7;
  padding: 0.375rem 0.5rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.esri-ui-corner {
  z-index: 8;
  width: 100%;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
svg text {
  stroke: #000;
  fill: #fff;
  stroke-opacity: 1 !important;
  font-weight: bold;
  stroke-width: 1px;
  font-family: Arial;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-search {
  width: 100%;
  height: 3rem;
  padding: 0.375rem;
  background: #e8ecf7;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
}
@media screen and (max-width: 50rem) {
  .esri-search {
    padding-left: 3rem;
  }
}
.esri-search .esri-search__container {
  border: 0.0625rem solid #d3d7e1;
  background: #fff;
}
.esri-search .esri-search__container.esri-search--show-suggestions {
  border-color: #1492dc;
}
.esri-search .esri-search__container:after {
  background: #f89303;
  margin-top: -0.4375rem;
}
.esri-search .esri-search__container:before {
  height: 0;
}
.esri-search .esri-search__container .esri-search__form .esri-search__input {
  font-size: 1rem;
  padding: 0.5rem;
  color: #5c6a86;
}
.esri-search .esri-search__container .esri-search__form .esri-search__input #placeholder {
  color: #8d98ad;
}
.esri-search .esri-search__container .esri-menu {
  margin-top: 0.4375rem;
  line-height: 1.5rem;
  -webkit-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
}
.esri-search .esri-search__container .esri-menu.esri-search__warning-menu {
  background: #f89303;
  color: #fff;
}
.esri-search .esri-search__container .esri-menu.esri-search__warning-menu .esri-search__warning-body {
  padding: 0.375rem 1rem;
}
.esri-search .esri-search__container .esri-widget-button {
  font-size: 1rem;
  width: 2.125rem;
  height: 2.125rem;
  border-left: none;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-basemap-gallery {
  -webkit-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
}
.esri-basemap-gallery__item-container {
  width: 24.3rem;
  display: block;
}
@media screen and (max-width: 50rem) {
  .esri-basemap-gallery__item-container {
    width: 16.2rem;
  }
}
.esri-basemap-gallery__item {
  width: 8.1rem;
  height: 7rem;
  padding: 0.5rem;
  border: none;
  box-sizing: border-box;
  display: block;
  float: left;
  text-align: center;
}
.esri-basemap-gallery__item:first-child {
  margin: 0;
}
.esri-basemap-gallery__item-title {
  padding: 0.5rem 0;
  line-height: 1rem;
  font-size: 0.875rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-home {
  bottom: 10rem;
  right: 1rem;
  position: fixed;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esriOverviewMap {
  margin: 0 4rem 1rem 0;
  border: 0.0625rem solid #d3d7e1;
}
.esriOverviewMap .ovwContainer {
  padding: 0 !important;
  border: 0.0625rem solid #d3d7e1;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
}
.esriOverviewMap .ovwController {
  bottom: 1rem;
  right: 1rem;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  position: fixed;
  box-sizing: border-box;
  background: #fafafa !important;
  border: 0.0625rem solid #d3d7e1;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.esriOverviewMap .ovwController:hover {
  background-color: #fff !important;
  color: #1492dc;
  -webkit-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
}
.esriOverviewMap .ovwController:active {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.esriOverviewMap .ovwController:focus {
  border-color: #1492dc !important;
  color: #1492dc;
}
.esriOverviewMap .ovwController:before {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  margin: 0.375rem;
  top: -0.0625rem;
  left: -0.0625rem;
  font-family: "icons" !important;
}
.esriOverviewMap .ovwController.ovwShow:before {
  content: "\e914";
}
.esriOverviewMap .ovwController.ovwShow:active {
  border-color: #1492dc !important;
}
.esriOverviewMap .ovwController.ovwShow:active:before {
  content: "\e915";
}
.esriOverviewMap .ovwController.ovwHide:before {
  content: "\e915";
  color: #1492dc;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-zoom {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  margin-bottom: 0 !important;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-popup--shadow {
  -webkit-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
  box-shadow: 0 8px 16px rgba(92, 136, 216, 0.24);
}
.esri-popup__header-title {
  font-weight: normal;
  font-size: 1rem;
  word-break: normal;
}
.esri-popup__text-primary {
  color: #5c6a86;
  font-size: 0.875rem;
}
.esri-popup__text-secondary, .esri-popup sup {
  color: #8d98ad;
  font-size: 0.75rem;
}
.esri-popup .esri-feature .esri-popup__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.esri-popup .esri-popup__action-text {
  display: none;
}

.esri-popup--is-docked-top-center .esri-popup__main-container,
.esri-popup--is-docked-bottom-center .esri-popup__main-container {
  max-height: 18.75rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.esri-coordinate-conversion {
  bottom: 1.8rem;
  right: 9rem;
  position: fixed;
}
.esri-coordinate-conversion__conversions-view {
  margin: 0;
}
.esri-coordinate-conversion__row {
  height: 2rem;
  padding: 0;
}
.esri-coordinate-conversion .esri-select {
  border: none;
}
.esri-coordinate-conversion .esri-coordinate-conversion__select-row {
  flex: 0 0 6rem;
  text-align-last: left;
}
.esri-coordinate-conversion .esri-coordinate-conversion__display {
  min-height: 2rem;
  padding: 0.5rem;
  line-height: 1rem;
  font-size: 0.875rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dx-invalid .dx-texteditor-container:after {
  display: none;
}

.dx-field {
  width: 100%;
  float: left;
  margin: 0 0 1rem;
}
.dx-field-label, .dx-field-value {
  width: 100%;
}
.dx-field--title .dx-texteditor.dx-editor-outlined {
  border-width: 0;
  border-bottom-width: 0.0625rem;
  border-radius: 0;
}
.dx-field--title .dx-texteditor.dx-editor-outlined .dx-placeholder, .dx-field--title .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding-left: 2.25rem;
}
.dx-field--title .dx-texteditor.dx-editor-outlined .dx-placeholder:before, .dx-field--title .dx-texteditor.dx-editor-outlined .dx-texteditor-input:before {
  padding-left: 0;
}
.dx-field-label {
  padding: 0.25rem 0.5rem;
  line-height: 1rem;
}

.dx-fieldset {
  padding: 0;
  margin: 0;
}

.dx-list {
  min-height: 2.25rem;
}
.dx-numberbox-spin-container {
  width: 1.5rem;
}

.dx-dropdowneditor-overlay > .dx-overlay-content .dx-popup-content {
  padding: 0;
}
.dx-dropdowneditor-overlay > .dx-overlay-content .dx-popup-content .dx-colorview {
  padding: 1rem;
}
.dx-list.dx-list--min {
  padding: 0;
}


dx-chart,
dx-pie-chart {
  padding: 1rem;
}
dx-chart svg text,
dx-pie-chart svg text {
  stroke-opacity: 0 !important;
  stroke: transparent;
  fill: #5c6a86;
}

.dx-item.dx-accordion-item.dx-item-selected.dx-accordion-item-opened .dx-template-wrapper.dx-item-content.dx-accordion-item-title {
  background: #e8ecf7;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dx-list-item-before-bag .dx-list-select-checkbox-container, .dx-list-item-before-bag .dx-list-select-radiobutton-container {
  width: 2.25rem;
}
.dx-list-item-before-bag .dx-list-select-checkbox {
  margin: 0;
}

.dx-checkbox.dx-checkbox-custom {
  height: 2.25rem;
  position: relative;
  display: block;
  margin: 0;
}
.dx-checkbox.dx-checkbox-custom:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 1.125rem;
  left: 1.125rem;
  opacity: 0;
  background: #1492dc;
  border-radius: 50%;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.dx-checkbox.dx-checkbox-custom .dx-checkbox-container {
  position: relative;
}
.dx-checkbox.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon {
  width: 1rem;
  height: 1rem;
  position: relative;
  background-color: transparent;
  border: 0.125rem solid #5c6a86;
  margin: 0.5rem;
  border-radius: 0.1875rem;
  box-sizing: content-box;
  overflow: visible;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.dx-checkbox.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon:before {
  content: "";
  position: absolute;
  width: 0.6rem;
  height: 0.3rem;
  border-left: 0.125rem solid #fff;
  border-bottom: 0.125rem solid #fff;
  top: 0.15rem;
  left: 0.15rem;
  margin: 0;
  opacity: 0;
  box-sizing: content-box;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.dx-checkbox.dx-checkbox-custom.filled:before,
.dx-checkbox-checked.dx-checkbox-custom:before {
  background: #32c34e;
}
.dx-checkbox.filled .dx-checkbox-container .dx-checkbox-icon,
.dx-checkbox-checked.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon {
  background-color: #32c34e;
  border-color: #32c34e;
}
.dx-checkbox.dx-checkbox-custom.filled .dx-checkbox-container .dx-checkbox-icon:before,
.dx-checkbox-checked.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon:before {
  opacity: 1;
}
.dx-checkbox.dx-checkbox-custom.dx-state-active:before,
.dx-checkbox.dx-checkbox-custom.dx-state-focused:before {
  width: 2.5rem;
  height: 2.5rem;
  top: -0.125rem;
  left: -0.125rem;
  opacity: 0.12;
}
.dx-checkbox.dx-checkbox-custom.dx-state-disabled .dx-checkbox-icon {
  opacity: 0.36;
  border-color: #8d98ad;
}
.dx-checkbox-checked.dx-checkbox-custom.dx-state-disabled .dx-checkbox-container .dx-checkbox-icon {
  background-color: #8d98ad;
  border-color: #8d98ad;
}


.dx-widget .dx-state-focused .dx-checkbox.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon {
  border: 0.125rem solid #5c6a86;
}
.dx-widget .dx-state-focused .dx-checkbox-checked.dx-checkbox-custom .dx-checkbox-container .dx-checkbox-icon {
  border-color: #32c34e;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.item-btn {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.dx-switch-container {
  height: 1.125rem;
}

.w-switch--one .dx-switch-handle:before {
  background-color: #5c6a86;
}
.w-switch--one .dx-switch-on-value .dx-switch-handle:before {
  background-color: #1492dc;
}
.w-switch--one .dx-switch.dx-state-focused .dx-switch-handle:before {
  background-color: #5c6a86;
}
.w-switch--one .dx-switch.dx-switch-on-value.dx-state-focused .dx-switch-handle:before {
  background-color: #1492dc;
}

.w-switch--active .dx-switch-handle:before {
  background-color: #1492dc;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dx-menu-base .dx-submenu {
  -webkit-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  -moz-box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  box-shadow: 0 4px 8px rgba(92, 136, 216, 0.24);
  border: none;
}
.dx-menu-base .dx-submenu .dx-menu-items-container {
  padding: 0;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item {
  padding: 0.375rem 1rem;
  line-height: 1.5rem;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item.dx-state-hover {
  background-color: transparent;
  color: #1492dc;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item.dx-state-disabled {
  opacity: 0.36;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content {
  padding: 0;
  position: relative;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-icon {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  left: -0.5rem;
  background-size: 100%;
  background-position: center;
  color: inherit;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-icon-context-menu-sort-asc {
  font-family: "icons" !important;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-icon-context-menu-sort-asc:before {
  content: "\e93c";
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-icon-context-menu-sort-desc {
  font-family: "icons" !important;
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-icon-context-menu-sort-desc:before {
  content: "\e940";
}
.dx-menu-base .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper .dx-item .dx-item-content .dx-menu-item-text {
  padding: 0 0 0 1.5rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
dx-tabs.dx-tabs .dx-button {
  background-color: transparent;
}
dx-tabs.dx-tabs .dx-tabs-wrapper {
  border: none;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item {
  background: none;
  padding: 0.75rem;
  line-height: 1.5rem;
  color: #8d98ad;
  position: relative;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.125rem;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: #1492dc;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab--filter {
  background: var(--clr-background-active);
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab--filter:after {
  content: "\e909";
  font-family: "icons" !important;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  top: auto;
  right: 0.75rem;
  bottom: 0.75rem;
  left: auto;
  color: #6667AB;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab--bookmark {
  background: rgba(223, 136, 183, 0.1);
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab--bookmark:after {
  content: "\e957";
  font-family: "icons" !important;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  top: auto;
  right: 0.75rem;
  bottom: 0.75rem;
  left: auto;
  color: #DF88B7;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-state-hover {
  color: #5c6a86;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected {
  color: #1492dc;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected .dx-item-content .dx-icon {
  color: #1492dc;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected:before {
  opacity: 1;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected:after {
  border: none;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  color: var(--clr-text);
  font-family: "icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon.dx-icon-layers:before {
  content: "\e906";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon.dx-icon-bookmarks:before, dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon.icon-bookmarks:before {
  content: "\e956";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon.dx-icon-legend:before {
  content: "\e95c";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-icon.dx-icon-filter:before {
  content: "\e908";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item .dx-tab-text {
  line-height: 1.5rem;
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected .dx-icon.dx-icon-layers:before {
  content: "\e907";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected .dx-icon.dx-icon-bookmarks:before {
  content: "\e957";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected .dx-icon.dx-icon-legend:before {
  content: "\e95d";
}
dx-tabs.dx-tabs .dx-tabs-wrapper .dx-item.dx-tab-selected .dx-icon.dx-icon-filter:before {
  content: "\e909";
}

.dx-tabs-nav-buttons .dx-tabs-scrollable .dx-tabs-wrapper {
  border: none;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


dx-tree-view.tree-view-layers .dx-treeview-node-container .dx-treeview-node .dx-treeview-item {
  padding: 0.375rem;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  box-sizing: border-box;
}
dx-tree-view.tree-view-layers .dx-treeview-node-container .dx-treeview-node .dx-treeview-item .dx-treeview-item-content > span {
  line-height: 1.5rem;
  display: inline-block;
}
dx-tree-view.tree-view-layers .dx-treeview-node-container .dx-treeview-node .dx-treeview-node-loadindicator {
  left: 0.5rem;
}
dx-tree-view.dx-treeview .dx-treeview-node.dx-treeview-item-with-checkbox .dx-checkbox {
  left: 2.5rem;
  z-index: 8;
}
dx-tree-view.dx-treeview .dx-treeview-node.dx-treeview-item-with-checkbox .dx-checkbox ~ .dx-treeview-item {
  padding-left: 3.5rem;
  font-size: 1rem;
  color: #8d98ad;
}
dx-tree-view.dx-treeview .dx-treeview-node.dx-treeview-item-with-checkbox .dx-checkbox-checked ~ .dx-treeview-item {
  color: #5c6a86;
}

.dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:before, .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:after,
.dx-treeview-node-container-opened > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:before,
.dx-treeview-node-container-opened > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:after {
  display: none;
}
.dx-treeview-node-container > .dx-treeview-node > .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:before, .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:after,
.dx-treeview-node-container-opened > .dx-treeview-node > .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:before,
.dx-treeview-node-container-opened > .dx-treeview-node > .dx-treeview-node-container > .dx-treeview-node > .dx-treeview-item > .dx-template-wrapper > .treeview-item:after {
  display: block;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dx-treelist .dx-link {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.375rem;
  display: inline-block;
  text-decoration: none;
  color: #5c6a86;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.dx-treelist .dx-link:before {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: "icons" !important;
}
.dx-treelist .dx-link:hover:before {
  color: #1492dc;
}

.dx-treelist-content .dx-treelist-table .dx-row .dx-command-edit {
  width: auto;
  min-width: 8rem;
}

.dx-treeview-node.dx-treeview-node--filter,
.dx-treeview-item-with-checkbox.dx-treeview-node--filter,
.dx-state-selected.dx-treeview-node--filter,
.dx-treeview-node-is-leaf.dx-treeview-node--filter {
  background: linear-gradient(90deg, rgba(102, 103, 171, 0.1) 0%, rgba(102, 103, 171, 0) 100%);
  border-radius: 5px;
  position: relative;
}
.dx-treeview-node.dx-treeview-node--filter:before,
.dx-treeview-item-with-checkbox.dx-treeview-node--filter:before,
.dx-state-selected.dx-treeview-node--filter:before,
.dx-treeview-node-is-leaf.dx-treeview-node--filter:before {
  content: "\e909";
  font-family: "icons" !important;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  top: 0.75rem;
  left: 0.75rem;
  color: #6667AB;
}
.dx-treeview-node.dx-treeview-node--bookmark,
.dx-treeview-item-with-checkbox.dx-treeview-node--bookmark,
.dx-state-selected.dx-treeview-node--bookmark,
.dx-treeview-node-is-leaf.dx-treeview-node--bookmark {
  background: linear-gradient(90deg, rgba(223, 136, 183, 0.1) 0%, rgba(223, 136, 183, 0) 100%);
  border-radius: 5px;
  position: relative;
}
.dx-treeview-node.dx-treeview-node--bookmark:after,
.dx-treeview-item-with-checkbox.dx-treeview-node--bookmark:after,
.dx-state-selected.dx-treeview-node--bookmark:after,
.dx-treeview-node-is-leaf.dx-treeview-node--bookmark:after {
  content: "\e957";
  font-family: "icons" !important;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  top: 0.75rem;
  left: 0.75rem;
  color: #DF88B7;
}
.dx-treeview-node.dx-treeview-node--bookmark.dx-treeview-node--filter,
.dx-treeview-item-with-checkbox.dx-treeview-node--bookmark.dx-treeview-node--filter,
.dx-state-selected.dx-treeview-node--bookmark.dx-treeview-node--filter,
.dx-treeview-node-is-leaf.dx-treeview-node--bookmark.dx-treeview-node--filter {
  background: linear-gradient(91.62deg, rgba(223, 136, 183, 0.1) 0.23%, rgba(102, 103, 171, 0.1) 47.96%, rgba(102, 103, 171, 0) 97.19%);
}
.dx-treeview-node.dx-treeview-node--bookmark.dx-treeview-node--filter:before,
.dx-treeview-item-with-checkbox.dx-treeview-node--bookmark.dx-treeview-node--filter:before,
.dx-state-selected.dx-treeview-node--bookmark.dx-treeview-node--filter:before,
.dx-treeview-node-is-leaf.dx-treeview-node--bookmark.dx-treeview-node--filter:before {
  top: 0.25rem;
}
.dx-treeview-node.dx-treeview-node--bookmark.dx-treeview-node--filter:after,
.dx-treeview-item-with-checkbox.dx-treeview-node--bookmark.dx-treeview-node--filter:after,
.dx-state-selected.dx-treeview-node--bookmark.dx-treeview-node--filter:after,
.dx-treeview-node-is-leaf.dx-treeview-node--bookmark.dx-treeview-node--filter:after {
  top: 1.25rem;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
dx-slider .dx-slider-wrapper {
  height: 1.5rem;
  margin: 0.375rem 0;
  padding: 0 0.75rem 0 0;
}
dx-slider .dx-slider-wrapper .dx-slider-bar {
  margin: 0.75rem 0.5rem;
  height: 0.125rem;
  background: #d3d7e1;
}
dx-slider .dx-slider-wrapper .dx-slider-bar .dx-slider-range.dx-slider-range-visible {
  border: none;
}
dx-slider .dx-slider-wrapper .dx-slider-bar .dx-slider-range .dx-slider-handle {
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  border-radius: 50%;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
dx-slider .dx-slider-wrapper .dx-slider-bar .dx-slider-range .dx-slider-handle:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  opacity: 0;
  background: #1492dc;
  border-radius: 50%;
  -webkit-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
dx-slider .dx-slider-wrapper .dx-slider-bar .dx-slider-range .dx-slider-handle.dx-state-focused:before {
  width: 2.25rem;
  height: 2.25rem;
  opacity: 0.16;
  top: -0.625rem;
  left: -0.625rem;
}
dx-slider .dx-slider-wrapper .dx-slider-bar .dx-slider-range .dx-slider-handle.dx-state-active:before {
  width: 1.5rem;
  height: 1.5rem;
  top: -0.25rem;
  left: -0.25rem;
  opacity: 1;
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal {
  border-radius: 0;
  border: none;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar {
  padding: 0.375rem;
  height: 3rem;
  box-sizing: border-box;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 2.25rem;
  min-height: 2.25rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before {
  position: relative;
  padding: 0 2.25rem 0 1rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label {
  font-size: 1.125rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label > .dx-item-content {
  padding: 0;
  margin: 0;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label > .dx-item-content > div {
  white-space: normal;
  line-height: 1.5rem;
  padding: 0.375rem 0;
  height: 2.25rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0.375rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon {
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin: 0;
  border: none;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-hover, .popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-active, .popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-focused {
  background-color: transparent;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-hover .dx-button-content .dx-icon:before, .popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-active .dx-button-content .dx-icon:before, .popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon.dx-state-focused .dx-button-content .dx-icon:before {
  color: #1492dc;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon .dx-button-content {
  width: 1.5rem;
  min-height: 1.5rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon .dx-button-content .dx-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1.5rem;
  font-size: 1.5rem;
  background-position: center;
  background-size: auto;
  position: relative;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-title.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-button-has-icon .dx-button-content .dx-icon:before {
  font-family: "icons";
  content: "\e91a";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  top: 0;
  left: 0;
  color: #5c6a86;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-content .dx-template-wrapper .popup-panel__buttons {
  width: 100%;
  position: absolute;
  margin: 0;
  bottom: 0;
  left: 0;
  text-align: right;
  border-top: 0.0625rem solid #e8ecf7;
  box-sizing: border-box;
  padding: 0.875rem;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-content .dx-template-wrapper .popup-panel__buttons > .dx-button {
  margin-left: 0.875rem;
  display: inline-block;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-content .dx-template-wrapper .popup-panel__content {
  padding: 0;
  position: absolute;
  width: 100%;
  height: calc(100% - 7rem);
  left: 0;
  top: 3rem;
  box-sizing: border-box;
}
.popup-panel.dx-popup-wrapper > .dx-popup-normal .dx-popup-content .dx-template-wrapper .popup-panel__content .dx-scrollview-content {
  padding: 1rem;
}

.dx-overlay-shader {
  background: rgba(1, 11, 38, 0.24);
}

.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-rotation {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  border-radius: 50%;
  display: inline-block;
  border-right: 0.25rem solid #f89303;
  border-top: 0.125rem solid #f89303;
  border-bottom: 0.125rem solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dx-popover-wrapper .dx-popup-normal {
  border: none;
}
.dx-popover-wrapper .dx-popup-normal .dx-popover-arrow:after {
  border: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.dx-popover-wrapper .dx-popup-normal .dx-popup-content {
  padding: 0;
}
.dx-treeview-item-with-checkbox .dx-checkbox.dx-checkbox-custom .dx-checkbox-container{top:0.25rem;}