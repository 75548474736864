@font-face {
    font-family: 'robotoregular';
    src: url('../assets/fonts/roboto-regular.woff2') format('woff2'),
         url('../assets/fonts/roboto-regular.woff') format('woff'),
         url('../assets/fonts/icomoon.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}
:root{
    --rem: 16px;
    --font-body:'robotoregular';
}
html{
    font-size: 1rem;
}
body{
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--font-body);
    color: var(--clr-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* BODY */
.body-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.body-md {
    font-size: 1rem;
    line-height: 1.5rem;
}
.body-sm {
    font-size: 0.8125rem;
    line-height: 1.125rem;
}
.body-xs {
    font-size: 0.625rem;
    line-height: 1rem;
}

/* HEADING */
.heading-xl {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 500;
}
.heading-lg {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 500;
}
.heading-md {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
}
.heading-sm {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
}
.heading-xs{
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

.gutter {
    margin-bottom: 1rem;
}
.text-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden;
}

.text-secondary{
    color: var(--clr-text-secondary);
}