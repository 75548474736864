:root{
    --min-width: 320px;

    /* COLORS */
    --clr-primary:#1492dc;
    --clr-accent:#f89303;
    --clr-warn:#f44336;
    --clr-success: #32c34e;

    --clr-background: white;
    --clr-background-secondary: #f3f6fc;
    --clr-background-hover: #e5e8ee;
    --clr-background-active: #e9f2ff;

    --clr-background-yellow: #fffcf4;
    --clr-background-yellow-secondary: #f1ede0;
    --clr-background-yellow-hover: #e1dbc7;

    --clr-text: rgb(0, 0, 0, 0.87);
    --clr-text-secondary: rgba(0, 0, 0, 0.68);
    --clr-text-muted: rgba(68, 71, 70, 0.72);
    --clr-text-invert:white;

    --clr-item-active: #074c75;

    --clr-border: #e1e3e1;

    /* TRANSITION */
    --transition: all .2s ease;
}