/*---hr---*/
hr{
  border:1px solid var(--clr-background);
  border-top-color:var(--clr-border);
}

/*---card---*/
.card{
  box-shadow:  0px 1px 1px 1px rgb(0 0 0 / 6%);
  border-radius: 6px;
  background: var(--clr-background);
  border:1px solid var(--clr-border);
}
.card.yellow{
  background-color: var(--clr-background-yellow);
}
.card.yellow hr{
  border-top-color: var(--clr-background-yellow-hover);
}

/*---chips---*/
.chip{
  background: var(--clr-background-secondary);
  border-radius: 18px;
  padding-left: 1rem;
}
.chip > a{
  color: var(--clr-text);
  cursor: pointer;
}

/*---list---*/
.list{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*---progress---*/
@keyframes indeterminate {
  0% { left: -60%; width: 60%; }
  50% { left: 50%; width: 30%; }
  100% { left: 160%; width: 20%; }
}
.progress {
  width: 100%;
  height: 0.125rem;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}
.progress:before {
  content: '';
  height: 100%;
  top: 0;
  position: absolute;
  background: var(--clr-accent);
  will-change: left, right;
  overflow: hidden;
  animation: indeterminate 1024ms linear infinite;
}

/*---measurement---*/
.measurement{
  width:360px;
  position: fixed;
  bottom:5rem;
  right: 3.75rem;
  z-index: 999;
}
@media (max-width: 400px) {
  .measurement{
    width: 288px;
  }
}

/*---dx-text-field---*/
.dx-text-field{
  position: relative;
}

.dx-text-field .dx-texteditor .dx-placeholder::before,
.dx-text-field .dx-texteditor .dx-texteditor-input{
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 6px 6px 0 0;
}
.dx-text-field .dx-texteditor{
  background-color: var(--clr-background-secondary);
  border-radius: 6px 6px 0 0;
}
.dx-text-field .dx-texteditor.dx-state-hover{
  background-color: var(--clr-background-hover);
}
.dx-text-field.yellow .dx-texteditor{
  background-color: var(--clr-background-yellow-secondary);
}
.dx-text-field.yellow .dx-texteditor.dx-state-hover{
  background-color: var(--clr-background-yellow-hover);
}

.dx-text-field:has(label) .dx-texteditor .dx-texteditor-container .dx-texteditor-input{
  padding-top: 1rem;
}

.dx-text-field > .dx-texteditor.dx-texteditor-empty ~ label{
  font-size: 1rem;
  line-height: 1.5rem;
  top: 0.75rem;
  pointer-events: none;
}
.dx-text-field > .dx-texteditor.dx-colorbox.dx-texteditor-empty ~ label{
  left: 2.5rem;
}
.dx-text-field > .dx-texteditor.dx-colorbox.dx-texteditor-empty.dx-state-focused ~ label{
  left: 1rem;
}

.dx-text-field > .dx-texteditor.dx-state-focused ~ label,
.dx-text-field .dx-texteditor ~ label{
  font-size: 0.75rem;
  line-height: 0.75rem;
  position: absolute;
  top: 0.25rem;
  left: 1rem;
  transition: var(--transition);
  color: var(--clr-text-muted);
}
.dx-text-field > .dx-texteditor.dx-texteditor-empty.dx-state-hover ~ label{
  color: var(--clr-text-muted);
}
.dx-text-field > .dx-texteditor.dx-state-hover ~ label{
  color: var(--clr-text);
}
.dx-text-field > .dx-texteditor.dx-texteditor-empty.dx-state-hover.dx-state-focused ~ label,
.dx-text-field > .dx-texteditor.dx-state-focused ~ label{
  color: var(--clr-primary);
}
.dx-text-field > .dx-texteditor.dx-state-hover.dx-invalid ~ label,
.dx-text-field > .dx-texteditor.dx-state-hover.dx-state-focused.dx-invalid ~ label,
.dx-text-field > .dx-texteditor.dx-invalid ~ label{
  color: var(--clr-warn);
}
.dx-text-field .dx-colorbox-color-result-preview {
  top: 1.6125rem;
}

/*---hr---*/
hr{
  border:1px solid var(--clr-background);
  border-top-color:var(--clr-border);
}

/*---card---*/
.card{
  box-shadow:  0px 1px 1px 1px rgb(0 0 0 / 6%);
  border-radius: 6px;
  border:1px solid var(--clr-border);
}
.card.yellow{
  background-color: var(--clr-background-yellow);
}
.card.yellow hr{
  border-top-color: var(--clr-background-yellow-hover);
}
.card-title{
  width: calc(100% - 3rem);
  overflow-wrap: break-word;
}

/*---chips---*/
.chip{
  background: var(--clr-background-secondary);
  border-radius: 18px;
  padding-left: 1rem;
}
.chip > a{
  color: var(--clr-text);
  cursor: pointer;
}

/*---list---*/
.list{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*---text-box---*/
.text-box-primary.dx-texteditor.dx-editor-filled.dx-state-focused,
.text-box-primary.dx-texteditor.dx-editor-filled.dx-state-hover,
.text-box-primary.dx-texteditor.dx-editor-filled{
  background: white;
}
.text-box-search.dx-texteditor.dx-editor-filled .dx-texteditor-input{
  padding-right: 3rem;
  padding-left: 3rem;
}
.text-box-search.dx-texteditor.dx-editor-filled .dx-placeholder::before{
  padding-left:3rem;
}

/*---progress---*/
@keyframes indeterminate {
  0% { left: -60%; width: 60%; }
  50% { left: 50%; width: 30%; }
  100% { left: 160%; width: 20%; }
}
.progress {
  width: 100%;
  height: 0.125rem;
  top: 0;
  left: 0;
  z-index: 8;
  position: absolute;
  overflow: hidden;
}
.progress:before {
  content: '';
  height: 100%;
  top: 0;
  position: absolute;
  background: var(--clr-accent);
  will-change: left, right;
  overflow: hidden;
  animation: indeterminate 1024ms linear infinite;
}