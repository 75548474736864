* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body {
    height: 100%;
    min-width: var(--min-width);
    background: var(--clr-background);
}

.flex-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.flex-row > div{
    flex:1;
}
.flex-col{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-end{
    justify-content: flex-end;
}
.align-items-center{
    align-items: center;
}
.space-between{
    justify-content: space-between;
}
.wrap{
    flex-wrap: wrap;
}
.content-padding{
    padding: 1rem;
}
.content-padding-xs{
    padding: 0.375rem;
}
.border-bottom{
    border-bottom: 1px solid var(--clr-border);
}

.gap-sm{
    gap:0.5rem;
}
.gap-none{
    gap:0;
}

.content-overflow{
    overflow: auto;
}